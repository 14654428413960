// Interface for environment configuration
import { ViewEncapsulation } from '@angular/core';

export enum EnvironmentEnum {
  development = 'development',
  integration = 'integration',
  recette = 'recette',
  qualification = 'qualification',
  production = 'production',
}

export interface EnvironmentInterface {
  apiUrl: string;
  env: string;
  production: boolean;
  defaultEncapsulation: ViewEncapsulation;
}
