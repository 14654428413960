import { HttpParams } from '@angular/common/http';
import { SortModel } from '../sort.model';
import { SortLangModel } from '../sort-lang.model';

export class PageableModel {
  static DEFAULT_PAGE_SIZE = 10;

  public page = 0;
  public size: number = PageableModel.DEFAULT_PAGE_SIZE;
  public sorts: SortModel[] = [];

  public toParams(): HttpParams {
    let options: HttpParams = new HttpParams();
    if (!isNaN(this.page)) {
      options = options.set('page', String(this.page));
    }
    if (!isNaN(this.size)) {
      options = options.set('size', String(this.size));
    }
    if (this.sorts && this.sorts.length > 0) {
      this.sorts
        .filter((sort) => !(sort instanceof SortLangModel))
        .forEach((sort) => {
          let sortLt = sort.property;
          if (sort.direction) {
            sortLt = sortLt + ',' + sort.direction;
          }
          options = options.append('sort', sortLt);
        });

      this.sorts
        .filter((sort) => sort instanceof SortLangModel)
        .forEach((s: SortModel) => {
          const sort = s as SortLangModel;

          const sortLt = sort.property + ',' + sort.langKey + ',' + sort.direction;

          if (options.keys().some((key) => key == sort.field)) {
            options.set(sort.field, options.get(sort.field) + ';' + sortLt);
          } else {
            options = options.append(sort.field, sortLt);
          }
        });
    }
    return options;
  }
}

export class DefaultPageable {
  static getPageable(): PageableModel {
    const pageable = new PageableModel();
    pageable.size = 9999999;
    return pageable;
  }
}
