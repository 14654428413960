import { SortModel, SortOrder } from './sort.model';

export class SortLangModel implements SortModel {
  static FIELD_NAME = 'field';
  property: string;
  direction: SortOrder;
  langKey: string;
  field: string;

  constructor(property: string, direction: SortOrder, field: string, langKey: string) {
    this.property = property;
    this.direction = direction;
    this.langKey = langKey;
    this.field = field;
  }
}
